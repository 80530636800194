@import "_global";

.intro {
  position: relative;
  display: flex;
  background-color: $cPrimary;
  min-height: calc(100vh - #{$dhHeader});
  padding-top: $dhHeader;
  color: $cWhite;
  @include ie-va-fix();
  z-index: 0;

  &__bg {
    @include position(absolute,0);
    &:before {
      @include position(absolute,0);
      content: '';
      background-color: rgba($cBlack, 0.65);
    }
  }

  &__row {
    position: static;
    flex-direction: column;
    height: auto;
    z-index: 1;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    &--space-between {
      justify-content: space-between;
    }
  }

  &__heading {
    max-width: 900px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
  }
  &__subheading {
    margin-bottom: 70px;
    position: relative;
  }
  &__inner {

    &--heading, &--subheading {
      position: absolute;
      &.relative {
        position: relative;
      }
      &:not(:first-child) {
        opacity: 0;
        visibility: hidden;
      }
    }
    &--subheading {
      bottom: 0;
    }

  }
  &__dot {
    margin: 0 4px;
    @include size(13px);
    border: 2px solid $cWhite;
    transition: background-color 0.4s;
    cursor: pointer;
    &.active {
      background-color: #9BB0B9;
    }
    &s {
      list-style-type: none;
      justify-content: center;
      padding: 0;
      @include margin(null auto 40px);
      display: flex;
      &.none {
        display: none;
      }
    }
  }

  &__scroll {
    @include position(absolute, 0% null null 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transform: rotate(-90deg);
    opacity: 0;
    @include breakpoint(tablet-portrait down) {
      display: none;
    }
    &--active {
      cursor: pointer;
    }
    &-label {
      margin: 0 0 0 20px;
      font-weight: $fwLight;
    }
  }

  .column {
    @include flex-grid-column(12,0);
    padding-left: 0;
    padding-right: 0;
  }
  @include breakpoint(tablet-landscape) {
    &--video {
      height: 56.25vw;
      min-height: 0;
      background-color: transparent;
    }
  }
  @include breakpoint(tablet-portrait down) {
    .embed-video__iframe {
      display: none;
    }
  }
  @include breakpoint(phone down) {
    &__dot {
     &s {
       margin-bottom: 20px;
     }
    }
    &__subheading {
      margin-bottom: 35px;
    }
  }

}
